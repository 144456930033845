import { Fragment, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useParams, useSearchParams } from 'react-router-dom';
import Spinner from '../spinner/Spinner';
import {
	LogOrderAttempt,
	LogFailedOrderAttempt,
	LogOrderResponse,
	ProcessOrder,
	GetProduct,
	CheckUser
} from '../../Services';
import './OrderForm.css';

const OrderForm = () => {
	const { productId } = useParams();
	const [ searchParams ] = useSearchParams();
	const [ formData, setFormData ] = useState({
		firstName   : '',
		lastName    : '',
		email       : '',
		phone       : '',
		nameOnCard  : '',
		cardNumbers : '',
		expMonth    : '',
		expYear     : '',
		cvc         : '',
		billingZip  : '',
		utm         : {
			UTMSource   : '',
			UTMMedium   : '',
			UTMCampaign : '',
			UTMTerm     : '',
			UTMContent  : ''
		},
		gclid       : '',
		fbclid      : '',
		ttclid      : '',
		url         : window.location.href
	});
	const [ errors, setErrors ] = useState({});
	const [ years, setYears ] = useState([]);
	const [ showContactInformation, setShowContactInformation ] = useState(
		true
	);
	const [ isOcu, setIsOcu ] = useState(false);
	const [ ocuId, setOcuId ] = useState();
	const [ isUserId, setIsUserId ] = useState(false);
	const [ requireNameOnCard, setRequireNameOnCard ] = useState(true);
	const [ btnText, setBtnText ] = useState('Submit');
	const digitsOnlyRegex = /\D+/g;
	const emailValidationRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
	const [ isSaving, setIsSaving ] = useState(false);
	const [ errorMsg, setErrorMsg ] = useState();
	const [ cvcError, setCvcError ] = useState(false);

	// handle form resizer
	useEffect(
		() => {
			window.formSizeNotify();
		},
		[ errors, errorMsg, cvcError, showContactInformation ]
	);

	useEffect(() => {
		window.addEventListener('resize', window.formSizeNotify);

		return () => {
			window.removeEventListener('resize', window.formSizeNotify);
		};
	}, []);

	// preload years for CC expiration
	useEffect(() => {
		const currentYear = new Date().getFullYear();
		let tempArray = [];

		for (let i = currentYear; i <= currentYear + 10; i++) {
			tempArray.push(i);
		}

		setYears(tempArray);
	}, []);

	// check if contact information is present in the url
	// or the hide param is in the url and hide the contact information section
	// Also, check for query string param values
	useEffect(
		() => {
			// console.log('search params', searchParams.keys());
			if (searchParams && searchParams.keys()) {
				let tempFormData = { ...formData };

				if (searchParams.has('firstName')) {
					tempFormData.firstName = searchParams.get('firstName');
				}
				if (searchParams.has('lastName')) {
					tempFormData.lastName = searchParams.get('lastName');
				}
				if (searchParams.has('email')) {
					tempFormData.email = searchParams.get('email');
				}
				if (searchParams.has('phone')) {
					tempFormData.phone = searchParams.get('phone');
				}

				if (searchParams.has('hide')) {
					if (searchParams.get('hide') === '1') {
						if (
							tempFormData.firstName &&
							tempFormData.firstName.trim() !== '' &&
							tempFormData.lastName &&
							tempFormData.lastName.trim() !== '' &&
							tempFormData.email &&
							tempFormData.email.trim() !== '' &&
							tempFormData.phone &&
							tempFormData.phone.trim() !== ''
						) {
							setShowContactInformation(false);
						}
					}
				}

				if (
					searchParams.has('firstName') &&
					searchParams.has('lastName')
				) {
					tempFormData.nameOnCard = `${searchParams.get(
						'firstName'
					)} ${searchParams.get('lastName')}`;
				}

				if (searchParams.has('utm_source')) {
					tempFormData.utm.UTMSource = searchParams.get('utm_source');
				}

				if (searchParams.has('utm_medium')) {
					tempFormData.utm.UTMMedium = searchParams.get('utm_medium');
				}

				if (searchParams.has('utm_campaign')) {
					tempFormData.utm.UTMCampaign = searchParams.get(
						'utm_campaign'
					);
				}

				if (searchParams.has('utm_term')) {
					tempFormData.utm.UTMTerm = searchParams.get('utm_term');
				}

				if (searchParams.has('utm_content')) {
					tempFormData.utm.UTMContent = searchParams.get(
						'utm_content'
					);
				}

				if (searchParams.has('gclid')) {
					tempFormData.gclid = searchParams.get('gclid');
				}

				if (searchParams.has('fbclid')) {
					tempFormData.fbclid = searchParams.get('fbclid');
				}

				if (searchParams.has('ttclid')) {
					tempFormData.ttclid = searchParams.get('ttclid');
				}

				if (searchParams.has('uid')) {
					tempFormData.userId = searchParams.get('uid');
					setIsUserId(true);
					setShowContactInformation(false);
				}

				setFormData(tempFormData);

				if (searchParams.has('ocuId')) {
					const ocu = searchParams.get('ocuId');
					setIsOcu(ocu && ocu.trim() !== '');

					if (ocu && ocu.trim() !== '') {
						setOcuId(ocu);
					}
				}

				if (
					searchParams.has('noc') &&
					searchParams.get('noc') === '0'
				) {
					setRequireNameOnCard(false);
				}

				if (searchParams.has('buttonText')) {
					const tempText = searchParams.get('buttonText');

					if (tempText.trim() !== '') {
						setBtnText(tempText.trim());
					}
				}
			}
		},
		[ searchParams ]
	);

	const handleChange = (e) => {
		if (Object.entries(errors)) {
			setErrors({});
		}
		setErrorMsg();

		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		await processOrderForm();
	};

	const validateUid = async () => {
		if (formData.userId && formData.userId.trim() !== '') {
			try {
				const validUid = await CheckUser({
					userId : formData.userId
				});

				if (!validUid || validUid === '0') {
					// console.log('disabling user id');
					setIsUserId(false);
					setShowContactInformation(true);
					setFormData({
						...formData,
						userId : null
					});
				}
			} catch (error) {
				// console.log('error user id');
				setIsUserId(false);
				setShowContactInformation(true);
				setFormData({
					...formData,
					userId : null
				});
			}
		}
	};

	useEffect(
		() => {
			if (isUserId) {
				validateUid();
			}
		},
		[ isUserId ]
	);

	useEffect(
		() => {
			if (isOcu && ocuId && ocuId.trim() !== '') {
				processOcu();
			}
		},
		[ isOcu, ocuId ]
	);

	const processOcu = async () => {
		// console.log('processing ocu...');
		const sessionId = ocuId;

		// console.log('session id', sessionId);

		// let's validate the session value exists and is saved
		if (sessionId && sessionId.trim() !== '') {
			// session exists, process ocu
			let data = {
				utm       : { ...formData.utm },
				ocuId     : sessionId,
				productId : productId,
				ocu       : true,
				url       : window.location.href
			};

			toggleIsSaving();

			LogOrderAttempt(data);

			const response = await ProcessOrder(data);

			LogOrderResponse(response);

			// console.log('response', response);

			if (!response.success) {
				// invalid OCU, show the regular form
				setIsOcu(false);
				setOcuId('');
				toggleIsSaving();

				// if (!response.hideErrorMessage) {
				// 	setErrorMsg(response.message);
				// }
			}

			if (response.redirectUrl && response.redirectUrl !== '') {
				let querySymbol = '?';

				if (response.redirectUrl.indexOf('?') > -1) {
					querySymbol = '&';
				}

				let redirectUrl = response.redirectUrl;

				if (response.orderId && response.orderId !== '') {
					redirectUrl = `${redirectUrl}${querySymbol}orderId=${response.orderId}`;
				}

				querySymbol = '?';
				if (redirectUrl.indexOf('?') > -1) {
					querySymbol = '&';
				}

				if (response.ocuId && response.ocuId !== '') {
					redirectUrl = `${redirectUrl}${querySymbol}ocuId=${response.ocuId}`;
				}

				window.top.location.href = redirectUrl;
			}
		}
	};

	const processOrderForm = async () => {
		let hasValidationErrors = false;
		const currentYear = new Date().getFullYear();
		const currentMonth = new Date().getMonth() + 1;

		if (!isUserId) {
			if (formData.firstName.trim() === '') {
				setErrors((prev) => ({
					...prev,
					firstName : true
				}));
				hasValidationErrors = true;
			}
			if (formData.lastName.trim() === '') {
				setErrors((prev) => ({
					...prev,
					lastName : true
				}));
				hasValidationErrors = true;
			}
			if (formData.email.trim() === '') {
				setErrors((prev) => ({
					...prev,
					email : 'Required'
				}));
				hasValidationErrors = true;
			} else {
				if (!formData.email.trim().match(emailValidationRegex)) {
					setErrors((prev) => ({
						...prev,
						email : 'Valid email address required.'
					}));
					hasValidationErrors = true;
				}
			}
		}
		// phone is optional so disabling this for now
		// if (formData.phone.trim() === '') {
		// 	setErrors((prev) => ({
		// 		...prev,
		// 		phone : true
		// 	}));
		// 	hasValidationErrors = true;
		// }
		if (requireNameOnCard && formData.nameOnCard.trim() === '') {
			setErrors((prev) => ({
				...prev,
				nameOnCard : 'Please enter a cardholders name.'
			}));
			hasValidationErrors = true;
		}
		if (formData.cardNumbers.trim() === '') {
			setErrors((prev) => ({
				...prev,
				cardNumbers :
					'Credit card numbers are required in order to continue.'
			}));
			hasValidationErrors = true;
		} else {
			const parsedValue = formData.cardNumbers
				.trim()
				.replace(digitsOnlyRegex, '');

			if (parsedValue.length < 8) {
				setErrors((prev) => ({
					...prev,
					cardNumbers :
						'Make sure to type in the entire credit card number.'
				}));
				hasValidationErrors = true;
			}
		}
		if (formData.cvc.trim() === '') {
			setErrors((prev) => ({
				...prev,
				cvc :
					'CVC is required in order to continue. It is located on the back of your card.'
			}));
			hasValidationErrors = true;
		}
		if (formData.billingZip.trim() === '') {
			setErrors((prev) => ({
				...prev,
				billingZip :
					'Billing ZIP code is required in order to continue.'
			}));
			hasValidationErrors = true;
		}
		if (formData.expMonth.trim() === '') {
			setErrors((prev) => ({
				...prev,
				expMonth : 'Please select a card expiration month.'
			}));
			hasValidationErrors = true;
		}
		if (formData.expYear.trim() === '') {
			setErrors((prev) => ({
				...prev,
				expYear : 'Please select a card expiration year.'
			}));
			hasValidationErrors = true;
		}
		if (formData.expYear.trim() === currentYear.toString()) {
			const selectedMonth = parseInt(formData.expMonth);

			if (
				formData.expMonth.trim() === '' ||
				selectedMonth < currentMonth
			) {
				setErrors((prev) => ({
					...prev,
					expDate : 'The card you are trying to use has expired.'
				}));
				hasValidationErrors = true;
			}
		}

		const cardNumbers = formData.cardNumbers
			.trim()
			.replace(digitsOnlyRegex, '');
		const customerId = sessionStorage.getItem('sofCId');

		let data = {
			...formData,
			cardNumbers : cardNumbers,
			customerId  : customerId,
			productId   : productId
		};

		if (data.expMonth && data.expMonth !== '') {
			data.expMonth = parseInt(data.expMonth);
		} else {
			data.expMonth = null;
		}
		if (data.expYear && data.expYear !== '') {
			data.expYear = parseInt(data.expYear);
		} else {
			data.expYear = null;
		}
		if (data.cvc && data.cvc !== '') {
			// data.cvc = parseInt(data.cvc);
			data.cvc = data.cvc;
		} else {
			data.cvc = null;
		}

		if (hasValidationErrors) {
			data.hasValidationErrors = hasValidationErrors;
			data.cardNumbers =
				data.cardNumbers && data.cardNumbers !== ''
					? `A value of ${data.cardNumbers
							.length} characters (without dashes)`
					: null;
			data.cvc =
				data.cvc && data.cvc !== ''
					? `A value of ${data.cvc} characters`
					: null;

			// now log the attempt
			LogFailedOrderAttempt(data);
			return;
		} else {
			let tempData = {
				...data
			};

			tempData.hasValidationErrors = hasValidationErrors;
			tempData.cardNumbers =
				data.cardNumbers && data.cardNumbers !== ''
					? data.cardNumbers.length
					: null;
			tempData.cvc = data.cvc && data.cvc !== '' ? data.cvc.length : null;

			LogOrderAttempt(tempData);
		}

		toggleIsSaving();

		const response = await ProcessOrder(data);

		LogOrderResponse(response);

		// console.log('response', response);

		if (response.success) {
			//
		} else {
			if (
				response.message &&
				response.message.indexOf('security code is invalid') > -1
			) {
				setCvcError(true);
				setErrors((prev) => ({
					cvc : 'Your Card Verification Code (CVC) is invalid.'
				}));
			} else {
				setCvcError(false);
				setErrorMsg(response.message);
			}
			toggleIsSaving();
		}

		if (response.customerId && response.customerId !== '') {
			sessionStorage.setItem('sofCId', response.customerId);
		}

		if (response.redirectUrl && response.redirectUrl !== '') {
			let querySymbol = '?';

			if (response.redirectUrl.indexOf('?') > -1) {
				querySymbol = '&';
			}

			let redirectUrl = response.redirectUrl;

			if (response.orderId && response.orderId !== '') {
				redirectUrl = `${redirectUrl}${querySymbol}orderId=${response.orderId}`;
			}

			querySymbol = '?';
			if (redirectUrl.indexOf('?') > -1) {
				querySymbol = '&';
			}

			if (response.ocuId && response.ocuId !== '') {
				redirectUrl = `${redirectUrl}${querySymbol}ocuId=${response.ocuId}`;
			}

			window.top.location.href = redirectUrl;
		}
	};

	const toggleIsSaving = () => {
		setIsSaving((prev) => !prev);
	};

	return (
		<div className="order-form-container">
			<form onSubmit={handleSubmit} className="order-form">
				{!isOcu ? (
					<Fragment>
						{showContactInformation && (
							<div className="contact-information">
								<div className="row contact-name">
									<div className="col-sm-6">
										<label
											htmlFor="firstName"
											className="form-label"
										>
											First Name:
										</label>
										<input
											type="text"
											id="firstName"
											name="firstName"
											className={`form-control ${errors &&
											errors.firstName
												? 'is-invalid'
												: ''}`}
											placeholder="First Name"
											value={formData.firstName}
											onChange={handleChange}
											disabled={isSaving}
										/>
										{errors &&
										errors.firstName && (
											<div className="invalid-feedback">
												Required
											</div>
										)}
									</div>
									<div className="col-sm-6">
										<label
											htmlFor="lastName"
											className="form-label"
										>
											Last Name:
										</label>
										<input
											type="text"
											id="lastName"
											name="lastName"
											placeholder="Last Name"
											className={`form-control ${errors &&
											errors.lastName
												? 'is-invalid'
												: ''}`}
											value={formData.lastName}
											onChange={handleChange}
											disabled={isSaving}
										/>
										{errors &&
										errors.lastName && (
											<div className="invalid-feedback">
												Required
											</div>
										)}
									</div>
								</div>
								<div className="row contact-details">
									<div className="col-sm-6 col-md-6">
										<label
											htmlFor="email"
											className="form-label"
										>
											Email:
										</label>
										<input
											type="text"
											id="email"
											name="email"
											placeholder="Email"
											className={`form-control ${errors &&
											errors.email
												? 'is-invalid'
												: ''}`}
											value={formData.email}
											onChange={handleChange}
											disabled={isSaving}
										/>
										{errors &&
										errors.email && (
											<div className="invalid-feedback">
												{errors.email}
											</div>
										)}
									</div>
									<div className="col-sm-6 col-md-6">
										<label
											htmlFor="phone"
											className="form-label"
										>
											Phone:
										</label>
										<InputMask
											mask="(999) 999-9999"
											value={formData.phone}
											onChange={handleChange}
											disabled={isSaving}
										>
											<input
												type="text"
												id="phone"
												name="phone"
												placeholder="(XXX) XXX-XXXX"
												className={`form-control ${errors &&
												errors.phone
													? 'is-invalid'
													: ''}`}
											/>
										</InputMask>
										{errors &&
										errors.phone && (
											<div className="invalid-feedback">
												Required
											</div>
										)}
									</div>
								</div>
							</div>
						)}
						<div className="card-information">
							<div className="row">
								{requireNameOnCard && (
									<div className="col-sm-12">
										<label
											htmlFor="nameOnCard"
											className="form-label"
										>
											Name on Card:
										</label>
										<input
											type="text"
											id="nameOnCard"
											name="nameOnCard"
											placeholder="Name on Card"
											className={`form-control ${errors &&
											errors.nameOnCard
												? 'is-invalid'
												: ''}`}
											value={formData.nameOnCard}
											onChange={handleChange}
											disabled={isSaving}
										/>
										{errors &&
										errors.nameOnCard && (
											<div className="invalid-feedback">
												Required
											</div>
										)}
									</div>
								)}
								<div className="col-sm-12">
									<label
										htmlFor="cardNumbers"
										className="form-label"
									>
										Card Numbers:
									</label>
									{/* <InputMask
										mask="9999-9999-9999-9999"
										value={formData.cardNumbers}
										onChange={handleChange}
										disabled={isSaving}
									> */}
									<input
										type="text"
										id="cardNumbers"
										name="cardNumbers"
										value={formData.cardNumbers}
										placeholder="Card Numbers"
										onChange={handleChange}
										className={`form-control ${errors &&
										errors.cardNumbers
											? 'is-invalid'
											: ''}`}
									/>
									{/* </InputMask> */}
									{errors &&
									errors.cardNumbers && (
										<div className="invalid-feedback">
											Required
										</div>
									)}
								</div>
							</div>
							<div className="row expiration-date">
								<div className="col-sm-3">
									<label
										htmlFor="expMonth"
										className="form-label"
									>
										Exp Month:
									</label>
									<select
										id="expMonth"
										name="expMonth"
										className={`form-select ${errors &&
										errors.expMonth
											? 'is-invalid'
											: ''}`}
										value={formData.expMonth}
										onChange={handleChange}
										disabled={isSaving}
									>
										<option value="" disabled />
										<option value="1">01</option>
										<option value="2">02</option>
										<option value="3">03</option>
										<option value="4">04</option>
										<option value="5">05</option>
										<option value="6">06</option>
										<option value="7">07</option>
										<option value="8">08</option>
										<option value="9">09</option>
										<option value="10">10</option>
										<option value="11">11</option>
										<option value="12">12</option>
									</select>
									{errors &&
									errors.expMonth && (
										<div className="invalid-feedback d-block">
											Required
										</div>
									)}
									{errors &&
									errors.expDate && (
										<div className="invalid-feedback d-block">
											{errors.expDate}
										</div>
									)}
								</div>
								<div className="col-sm-3">
									<label
										htmlFor="expYear"
										className="form-label"
									>
										Exp Year:
									</label>
									<select
										id="expYear"
										name="expYear"
										className={`form-select ${errors &&
										errors.expYear
											? 'is-invalid'
											: ''}`}
										value={formData.expYear}
										onChange={handleChange}
										disabled={isSaving}
									>
										<option value="" disabled />
										{years.map((year, index) => (
											<option key={index} value={year}>
												{year}
											</option>
										))}
									</select>
									{errors &&
									errors.expYear && (
										<div className="invalid-feedback d-block">
											Required
										</div>
									)}
								</div>

								<div className="col-sm-3">
									<label htmlFor="cvc" className="form-label">
										CVC:
									</label>
									<input
										type="text"
										id="cvc"
										name="cvc"
										placeholder="CVC"
										className={`form-control ${errors &&
										errors.cvc
											? 'is-invalid'
											: ''}`}
										value={formData.cvc}
										onChange={handleChange}
										disabled={isSaving}
									/>
									{errors &&
									errors.cvc && (
										<div className="invalid-feedback">
											Required
										</div>
									)}
								</div>
								<div className="col-sm-3">
									<label
										htmlFor="billingZip"
										className="form-label"
									>
										Billing ZIP:
									</label>
									<input
										type="text"
										id="billingZip"
										name="billingZip"
										placeholder="Billing ZIP"
										className={`form-control ${errors &&
										errors.billingZip
											? 'is-invalid'
											: ''}`}
										value={formData.billingZip}
										onChange={handleChange}
										disabled={isSaving}
									/>
									{errors &&
									errors.billingZip && (
										<div className="invalid-feedback">
											Required
										</div>
									)}
								</div>
							</div>

							<div className="row cards-notice">
								<div className="col-sm-12">
									NOTE: We do not accept prepaid or temporary
									cards.
								</div>
							</div>
						</div>
						{!cvcError &&
						errorMsg &&
						errorMsg !== '' && (
							<Fragment>
								<div className="alert alert-danger mt-3 mb-0">
									<small>{errorMsg}</small>
								</div>
							</Fragment>
						)}

						{cvcError && (
							<Fragment>
								<div className="alert alert-danger mt-3 mb-0">
									<div>
										<strong>
											Your Card Verification Code (CVC) is
											invalid.
										</strong>
									</div>
									<small>
										The card verification code (CVC) is the
										3 or 4 digit number printed on the back
										of your card.
									</small>
								</div>
							</Fragment>
						)}
						<div className="row">
							<div className="d-grid">
								<button
									type="submit"
									className="btn btn-primary col-sm-12 mt-3 btn-submit"
									disabled={isSaving}
								>
									{!isSaving ? (
										<Fragment>
											<span>{btnText}</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner /> <div>Processing...</div>
										</Fragment>
									)}
								</button>
							</div>
						</div>
					</Fragment>
				) : (
					<div className="text-center">
						<Spinner />
					</div>
				)}
			</form>
		</div>
	);
};

export default OrderForm;
