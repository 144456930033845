import axios from 'axios';

// const apiHost = 'https://localhost:7173/';
const apiHost = 'https://ordersapi.realwebsite.com/';

export const GetProduct = async (id) => {
	const response = await axios
		.get(apiHost + 'products/' + id, null, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const CheckUser = async (data) => {
	const response = await axios
		.post(apiHost + 'orders/check-user', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const ProcessOrder = async (data) => {
	const response = await axios
		.post(apiHost + 'orders/process', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const LogFailedOrderAttempt = async (data) => {
	const response = await axios
		.post(apiHost + 'logs/failed', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const LogOrderAttempt = async (data) => {
	const response = await axios
		.post(apiHost + 'logs/success', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};

export const LogOrderResponse = async (data) => {
	const response = await axios
		.post(apiHost + 'logs/response', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		})
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return { data: { IsSuccessful: false, Message: err } };
		});

	return response.data;
};
