import OrderForm from '../orderForm/OrderForm';
import './MainApp.css';

const MainApp = () => {
	return (
		<div className="container-fluid">
			<OrderForm />
		</div>
	);
};

export default MainApp;
