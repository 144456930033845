import './App.css';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import MainApp from './components/mainApp/MainApp';
import { useEffect, useState } from 'react';

function App() {
	const [ searchParams ] = useSearchParams();
	const [ version, setVersion ] = useState();

	useEffect(
		() => {
			if (searchParams && searchParams.keys()) {
				if (searchParams.has('version')) {
					setVersion(searchParams.get('version'));
				}
			}
		},
		[ searchParams ]
	);

	return (
		<div className={`App${version ? ' ' + version : ''}`}>
			<Routes>
				<Route path="/:productId" element={<MainApp />} />
			</Routes>
		</div>
	);
}

export default App;
